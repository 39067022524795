import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../common/PageHeader"
import Link from "../common/Link"
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/solid"
export default function Contact() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 72 }) {
        id
        content
        title
        contactInfo {
          addressContact
          emailContact
          facebookContact
          instagramContact
          openingHoursContact
          phoneContact
          phone2Contact
        }
      }
    }
  `)
  const {
    addressContact,
    emailContact,
    facebookContact,
    instagramContact,
    openingHoursContact,
    phoneContact,
    phone2Contact,
  } = data.wpPage.contactInfo
  const { content, title } = data.wpPage
  return (
    <div className="wp-page  min-h-[70vh] h-full">
      <div className="px-4 lg:px-8 space-y-4 lg:space-y-8 max-w-4xl mx-auto">
        <PageHeader text={title} />

        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">
            {addressContact && (
              <div
                dangerouslySetInnerHTML={{ __html: addressContact }}
                className="wp-content"
              />
            )}
          </div>
          <div className="flex-1 space-y-4">
            {openingHoursContact && (
              <div
                dangerouslySetInnerHTML={{ __html: openingHoursContact }}
                className="wp-content"
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {emailContact && (
            <div className="flex flex-row items-center gap-4">
              <div className="w-6">
                <EnvelopeIcon />
              </div>
              <div>
                <a href={`mailto:${emailContact}`} className="underline">
                  {emailContact}
                </a>
              </div>
            </div>
          )}
          {phoneContact && (
            <div className="flex flex-row items-center gap-4">
              <div className="w-6">
                <PhoneIcon />
              </div>
              <div>
                {phoneContact}
                <br />
                {phone2Contact}{" "}
              </div>
            </div>
          )}
        </div>
        <div>
          {" "}
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="wp-content"
          />
        </div>
      </div>
    </div>
  )
}
