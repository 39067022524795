import React from "react"
import Contact from "../components/contact/Contact"

import Layout from "../components/layout"

import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Work" />

    <div className="custom-page-wrapper">
      <Contact />
    </div>
  </Layout>
)

export default ContactPage
